import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "../../data-grid/multiple-select-operator";
import { EventStatuses, LookupTypes } from "../../../types/enums";
import { AssetDto, LookupDto, LynxGridColDef, PermitDto, UserDto } from "types";
import { GridFilterOperator } from "@mui/x-data-grid-pro";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetPermitsQuery } from "services/rtkApi/endpoints/permits";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
var utmObj = require("utm-latlng");
var utm = new utmObj();
const utmPercision = 1;

export const getEventColumns = (
  hasPermitFeature?: boolean,
  isForImport?: boolean,
  predefinedLookups?: any,
  isEventDateOnly?: boolean
): LynxGridColDef[] => {
  return [
    {
      field: "eventNumber",
      headerName: "Event #",
      width: 90,
      type: "string",
      headerClassName: "event-number-header",
    },
    {
      field: "eventDueDateTime",
      headerName: "Due Date",
      width: 110,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: isEventDateOnly ? "eventDate" : "eventDateTime",
      headerName: "Completed Date",
      width: 200,
      type: isEventDateOnly ? "date" : "dateTime",
      valueGetter: (value: any) => {
        return isEventDateOnly
          ? dateUtil.convertColumnDateTimeToLocalDateOnly(value)
          : dateUtil.convertColumnDateTimeToLocal(value);
      },
      importable: true,
      updateable: true,
      nullable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      type: "singleSelect",
      headerClassName: "event-status-header",
      valueOptions: [
        "Complete",
        "Incomplete",
        "Incomplete (Overdue)",
        "Pending Actions",
        "Pending Review",
        "Pending Email Review",
      ],
      renderCell: (params: any) => (
        <div className="event-status">
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(params.row) }}
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "assignedToUserFullNames",
      headerName: "Assigned To",
      width: 150,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: UserDto) => (!value ? "" : value.fullName),
      getOptionLabel: (value: UserDto) => (!value ? "" : value.fullName),
      importable: true,
      renderCell: (params: any) => <div>{params.value}</div>,
      filterOperators: [
        ...multipleSelectContainsOperator,
        ...multipleSelectDoesNotContainOperator,
      ] as GridFilterOperator<any, any, any>[],
      sortable: false,
    },
    {
      field: "urgency",
      headerName: "Urgency",
      width: 100,
      type: "singleSelect",
      importable: true,
      valueOptions: ["Low", "Medium", "High"],
      updateable: true,
      nullable: true,
      renderCell: (params: any) => (
        <div>
          {params.value && (
            <>
              <i
                className={"fe fe-circle "}
                style={{ color: urgencyColorSwitch(params.row) }}
              />
              &nbsp;{params.value}
            </>
          )}
        </div>
      ),
    },

    {
      field: "eventCategory",
      headerName: "Category",
      width: 150,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.EventCategory },
      getOptionValue: (value: LookupDto) => (!value ? "" : value.code),
      getOptionLabel: (value: LookupDto) => (!value ? "" : value.code),
      updateable: true,
      nullable: false,
      dtoId: "eventCategoryId",
    },

    {
      field: "eventSubCategory",
      headerName: "Subcategory",
      width: 150,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.EventSubCategory },
      getOptionValue: (value: LookupDto) => (!value ? "" : value.code),
      getOptionLabel: (value: LookupDto) => (!value ? "" : value.code),
      updateable: true,
      nullable: true,
      dtoId: "eventSubCategoryId",
    },

    ...(!isForImport ? [assetNameColumn] : [assetNamesColumn]),

    ...(hasPermitFeature ? [permitNamesColumn] : []),
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: "followUpRequired",
      headerName: "Follow up required?",
      width: 200,
      type: "boolean",
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: "isConfidential",
      headerName: "Confidential?",
      width: 200,
      type: "boolean",
      updateable: true,
      nullable: true,
    },
    {
      field: "followUpDate",
      headerName: "Follow Up Date",
      width: 200,
      type: "date",
      updateable: true,
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
      importable: true,
      nullable: true,
    },
    {
      field: "followUpDescription",
      headerName: "Follow Up Description",
      width: 300,
      type: "string",
      updateable: true,
      importable: true,
      nullable: true,
    },
    {
      field: "createdByScheduler",
      headerName: "Scheduler Event?",
      width: 150,
      type: "boolean",
      disabled: true,
    },
    {
      field: "reviewCompleted",
      headerName: "Review Completed",
      width: 150,
      type: "boolean",

      disabled: true,
    },
    {
      field: "lastReviewedBy",
      headerName: "Last Reviewed By",
      width: 200,
      type: "singleSelect",
      disabled: true,
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: UserDto) => (!value ? "" : value.fullName),
      getOptionLabel: (value: UserDto) => (!value ? "" : value.fullName),
      useInDashboard: false,
    },
    {
      field: "lastReviewedDateTimeUtc",
      headerName: "Last Review Date",
      width: 150,
      type: "dateTime",
      useInDashboard: false,
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
      disabled: true,
    },
    {
      field: "co2Emissions",
      headerName: "kg/CO2",
      width: 100,
      type: "number",
      filterable: false,
      sortable: false,
      disabled: true,
    },
    {
      field: "numberOfAttachments",
      headerName: "# of attachments",
      width: 150,
      type: "number",
    },
    {
      field: "createdDateTimeUtc",
      headerName: "Created Datetime",
      width: 150,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
  ];
};

const permitNamesColumn: LynxGridColDef = {
  field: "permitNames",
  headerName: "Permit Names",
  width: 150,
  type: "singleSelect",
  //MC TODO make sure we load all permits, not one page
  query: useGetPermitsQuery,
  getOptionValue: (value: PermitDto) => (!value ? "" : value.permitNumber),
  getOptionLabel: (value: PermitDto) => (!value ? "" : value.permitNumber),
  filterOperators: [
    ...multipleSelectContainsOperator,
    ...multipleSelectDoesNotContainOperator,
  ],
  sortable: false,
  renderCell: (params: any) => <div>{params.value}</div>,
};

const assetNameColumn: LynxGridColDef = {
  field: "assetNames",
  headerName: "Asset Names",
  width: 150,
  type: "singleSelect",
  query: useGetAssetsLookupsQuery,
  getOptionValue: (value: AssetDto) => (!value ? "" : value.name),
  getOptionLabel: (value: AssetDto) => (!value ? "" : value.name),
  renderCell: (params: any) => <div>{params.value}</div>,
  sortable: false,
  filterOperators: [
    ...multipleSelectContainsOperator,
    ...multipleSelectDoesNotContainOperator,
  ],
};

const assetNamesColumn: LynxGridColDef = {
  field: "assetName",
  headerName: "Asset Name",
  width: 150,
  type: "singleSelect",
  query: useGetAssetsLookupsQuery,
  getOptionValue: (value: AssetDto) => (!value ? "" : value.name),
  getOptionLabel: (value: AssetDto) => (!value ? "" : value.name),

  importable: true,
  updateable: true,
  nullable: false,
  dtoId: "assetId",
} as LynxGridColDef;
export const latLongColumns = [
  {
    field: "latitude",
    headerName: "Latitude",
    width: 100,
    type: "number",
    isEventField: true,
    disabled: true,
    valueFormatter: (params: any) => {
      if (!params.value) {
        return "";
      }

      return params.value;
    },
  },
  {
    field: "longitude",
    headerName: "Longitude",
    width: 100,
    isEventField: true,
    type: "number",
    disabled: true,
    valueFormatter: (params: any) => {
      if (!params.value) {
        return "";
      }

      return params.value;
    },
  },
];

export const utmColumns = [
  {
    field: "utmLetter",
    headerName: "UTM Letter",
    width: 100,
    isEventField: true,
    type: "string",
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params: any) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{params.row.latitude ? utmValues.ZoneLetter : ""}</div>;
    },
  },
  {
    field: "utmNumber",
    headerName: "UTM Number",
    width: 100,
    type: "string",
    isEventField: true,
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params: any) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{params.row.latitude ? utmValues.ZoneNumber : ""}</div>;
    },
  },
  {
    field: "utmEasting",
    headerName: "UTM Easting",
    isEventField: true,
    width: 100,
    type: "number",
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params: any) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{params.row.latitude ? utmValues.Easting : ""}</div>;
    },
  },
  {
    field: "utmNorthing",
    headerName: "UTM Northing",
    isEventField: true,
    width: 100,
    filterable: false,
    sortable: false,
    disabled: true,
    type: "number",
    renderCell: (params: any) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{params.row.latitude ? utmValues.Northing : ""}</div>;
    },
  },
];

export const statusColorSwitch = (row: any) => {
  switch (_.toLower(row.status)) {
    case _.toLower(EventStatuses.Complete):
      return lynxColors.primary;
    case _.toLower(EventStatuses.Incomplete):
      return lynxColors.success;
    case _.toLower(EventStatuses.PendingReview):
    case _.toLower(EventStatuses.PendingActions):
    case _.toLower(EventStatuses.PendingEmailReview):
      return lynxColors.harvestOrange;
    case _.toLower(EventStatuses.IncompleteOverdue):
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};

export const urgencyColorSwitch = (row: any) => {
  switch (row.urgency) {
    case "Low":
      return lynxColors.success;
    case "Medium":
      return lynxColors.warning;
    case "High":
      return lynxColors.error;
    default:
      return "";
  }
};
